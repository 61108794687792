import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SensorModel} from '../modulos/avp/models/sensor.model';

@Pipe({
    name: 'severidade_leitura'
})
export class SeveridadeLeituraPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {
    }

    transform(value: SensorModel, eixo: string, valorEixo: number): string {

        // Deixa o valor como absoluto pois o deslocamento pode ser positivo ou negativo
        valorEixo = Math.abs(valorEixo);

        if (eixo.toUpperCase() == 'X') {
            if (value.limiteDeslocamentoXPerigo > 0 && valorEixo >= value.limiteDeslocamentoXPerigo) {
                return 'danger';
            }

            // if (value.limiteDeslocamentoXAviso > 0 && (valorEixo >= value.limiteDeslocamentoXAviso) && (valorEixo < value.limiteDeslocamentoXPerigo)) {
            if (value.limiteDeslocamentoXAviso > 0 && valorEixo >= value.limiteDeslocamentoXAviso) {
                return 'warning';
            }

            // if (value.limiteDeslocamentoXAviso <= 0 || value.limiteDeslocamentoXPerigo <= 0 || (valorEixo <= value.limiteDeslocamentoXAviso && valorEixo <= value.limiteDeslocamentoXPerigo)) {
            //     return 'success';
            // }
            return 'success';
        }

        if (eixo.toUpperCase() == 'Y') {
            if (value.limiteDeslocamentoYPerigo > 0 && valorEixo >= value.limiteDeslocamentoYPerigo) {
                return 'danger';
            }

            // if (value.limiteDeslocamentoYAviso > 0 && (valorEixo >= value.limiteDeslocamentoYAviso) && (valorEixo < value.limiteDeslocamentoYPerigo)) {
            if (value.limiteDeslocamentoYAviso > 0 && valorEixo >= value.limiteDeslocamentoYAviso) {
                return 'warning';
            }

            // if (value.limiteDeslocamentoYAviso <= 0 || value.limiteDeslocamentoYPerigo <= 0 || (valorEixo <= value.limiteDeslocamentoYAviso && valorEixo <= value.limiteDeslocamentoYPerigo)) {
            //     return 'success';
            // }
            return 'success';
        }

        if (eixo.toUpperCase() == 'Z') {
            if (value.limiteDeslocamentoZPerigo > 0 && valorEixo >= value.limiteDeslocamentoZPerigo) {
                return 'danger';
            }

            // if (value.limiteDeslocamentoZAviso > 0 && (valorEixo >= value.limiteDeslocamentoZAviso) && (valorEixo < value.limiteDeslocamentoZPerigo)) {
            if (value.limiteDeslocamentoZAviso > 0 && valorEixo >= value.limiteDeslocamentoZAviso) {
                return 'warning';
            }

            // if (value.limiteDeslocamentoZAviso <= 0 || value.limiteDeslocamentoZPerigo <= 0 || (valorEixo <= value.limiteDeslocamentoZAviso && valorEixo <= value.limiteDeslocamentoZPerigo)) {
            //     return 'success';
            // }
            return 'success';
        }

        return '';
    }
}
