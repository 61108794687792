import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'extensaoarquivo'
})
export class ExtensaoArquivoPipe implements PipeTransform {

    transform(value: string): string {
        if (!value || value.trim() == '') {
            return '';
        }
        const temp = value.split('.');
        return temp[temp.length - 1];
    }
}
