import {Pipe, PipeTransform} from '@angular/core';
import {Funcoes} from '../_helpers/funcoes';

@Pipe({
    name: 'horacompleta'
})
export class HoraCompletaPipe implements PipeTransform {
    transform(value: any): any {
        if (!value || value.toString().trim() == '') {
            return '';
        }

        let ret = '';

        if (value instanceof Date) {
            value = value.toLocaleString();
        }

        const tmpValue = value.split(' ');
        if (tmpValue[0]) {
            ret = Funcoes.formatarData(tmpValue[0]);
        }

        ret += ' às ';

        if (tmpValue[1]) {
            ret += Funcoes.formatarHora(tmpValue[1]);
        } else {
            ret += '00:00';
        }

        return ret;
    }
}
