import {Pipe, PipeTransform} from '@angular/core';
import {Funcoes} from '../_helpers/funcoes';

@Pipe({
    name: 'cnpjcpf'
})
export class CnpjCpfPipe implements PipeTransform {

    // Recebe um path e retorna o último nome
    transform(value: string): string {
        return Funcoes.formatarCnpjCpf(value);
    }
}
