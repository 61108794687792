import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filtroprimeiraletra'
})
export class FiltroPrimeiraLetraPipe implements PipeTransform {

    transform(data: any[], atributo: any, letra: string): any {
        if (!data || !atributo) {
            return data;
        }
        return data.filter(item => item[atributo].toString().trim().toUpperCase().substr(0, 1) == letra.toUpperCase().trim());
    }
}
