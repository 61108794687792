import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filtroporextensao'
})
export class FiltroPorExtensaoPipe implements PipeTransform {

    transform(lista: any[], campoFiltro: string, listaExtensoes: string[]): any[] {
        if (!lista) {
            return [];
        }
        if (!campoFiltro || !listaExtensoes || listaExtensoes.length == 0) {
            return lista;
        }

        return lista.filter((item) => {
                const temp = item[campoFiltro].toLowerCase().split('.');
                const extArquivo = temp[temp.length - 1];
                if (extArquivo) {
                    return listaExtensoes.includes(extArquivo);
                }
                return true;
            }
        );
    }
}
