import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'nomeArquivoCurto'
})
export class NomeArquivoCurtoPipe implements PipeTransform {

    // Recebe um path e retorna o último nome
    transform(value: string, qde: number): string {
        if (!value || value.trim() == '') {
            return '';
        }
        let temp = value.split('/');
        let nomeArquivo = temp[temp.length - 1];

        temp = nomeArquivo.split('.');
        let extensao = '';
        if (temp.length > 1) {
            extensao = '.' + temp[temp.length - 1];
        }

        nomeArquivo = temp[0];
        let nomeCurto = '';
        if (nomeArquivo.length <= qde) {
            nomeCurto = nomeArquivo;
        } else {
            nomeCurto = nomeArquivo.substring(0, qde) + '...';
        }

        return nomeCurto + extensao;
    }
}
