import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'url'
})
export class UrlPipe implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) {
    }

    transform(value: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}
