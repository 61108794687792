import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'array_map'
})
export class ArrayMapPipe implements PipeTransform {
    constructor() {
    }

    transform(arr: any[], campoMapa: string, caracterJoin?: string | null): any[] | string {

        if (arr && Array.isArray(arr) && arr.length > 0) {
            const arrResultado = arr.map(c => c[campoMapa]);
            if (caracterJoin) {
                return arrResultado.join(caracterJoin);
            }
            return arrResultado;
        }

        return '';
    }
}
