import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'nomearquivo'
})
export class NomeArquivoPipe implements PipeTransform {

    // Recebe um path e retorna o último nome
    transform(value: string): string {
        if (!value || value.trim() == '') {
            return '';
        }
        const temp = value.split('/');
        return temp[temp.length - 1];
    }
}
