import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

    transform(value: string): any {
        if (!value) {
            return '';
        }

        // Deixa apenas os números
        value = value.replace(/\D+/ig, '');

        const tamanho = value.length;

        if (tamanho <= 8) {
            // Telefone
            value = value.substr(0, 4) + '-' + value.substr(-4);
        } else {
            if (tamanho <= 9) {
                // Celular
                value = value.substr(0, 1) + ' ' + value.substr(1, 4) + '-' + value.substr(-4);
            } else {
                if (tamanho <= 10) {
                    // Telefone com DDD
                    value = '(' + value.substr(0, 2) + ') ' + value.substr(2, 4) + '-' + value.substr(-4);
                } else {
                    // Celular com DDD
                    value = '(' + value.substr(0, 2) + ') ' + value.substr(2, 1) + ' ' + value.substr(3, 4) + '-' + value.substr(-4);
                }
            }
        }

        return value;
    }
}
