import {Pipe, PipeTransform} from '@angular/core';
import {Funcoes} from '../_helpers/funcoes';

@Pipe({
    name: 'data'
})
export class DataPipe implements PipeTransform {
    transform(value: any): any {
        if (!value || value.toString().trim() == '') {
            return '';
        }

        return Funcoes.formatarData(value);
    }
}
