import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'dataexibicao'
})
export class DataExibicaoPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    transform(value: any): any {
        if (!value || value.toString().trim() == '') {
            return '';
        }

        return this.dataParaExibicao(value);
    }

    private dataParaExibicao(dataStr: string) {
        let agora = moment();
        let data = moment(dataStr);

        // Deixa sempre a variável agora maior, para funcionar o diff do moment
        if (data.isBefore(agora)) {
            const tmp = agora;
            agora = data;
            data = tmp;
        }

        const ano = data.diff(agora, 'years');
        if (ano > 0) {
            if (ano == 1) {
                return this.translateService.instant('GERAIS.TXT_HA_MAIS_DE') + ' ' + ano + ' ' + this.translateService.instant('GERAIS.TXT_ANO');
            }
            return this.translateService.instant('GERAIS.TXT_HA_MAIS_DE') + ' ' + ano + ' ' + this.translateService.instant('GERAIS.TXT_ANOS');
        } else {
            const mes = data.diff(agora, 'months');
            if (mes == 0) {
                const dia = data.diff(agora, 'days');
                if (dia == 0) {
                    const hora = data.diff(agora, 'hours');
                    if (hora == 0) {
                        return data.format('HH:mm') + ' ' + this.translateService.instant('GERAIS.TXT_DE_HOJE');
                    } else {
                        if (hora == 1) {
                            return this.translateService.instant('GERAIS.TXT_UMA_HORA_ATRAS');
                        }
                    }
                    return hora + ' ' + this.translateService.instant('GERAIS.TXT_HORAS_ATRAS');
                } else {
                    if (dia === 1) {
                        return this.translateService.instant('GERAIS.TXT_ONTEM_AS') + ' ' + data.format('HH:mm');
                    }
                }
                return dia + ' ' + this.translateService.instant('GERAIS.TXT_DIAS_ATRAS');

            } else {
                if (mes > 0) {
                    if (mes == 1) {
                        return mes + ' ' + this.translateService.instant('GERAIS.TXT_MES_ATRAS');
                    }
                    return mes + ' ' + this.translateService.instant('GERAIS.TXT_MESES_ATRAS');
                }
            }
        }

        return data.format('DD/MM/YYYY');
    }

}
