import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormatoHoraPipe} from './formato-hora.pipe';
import {HoraCompletaPipe} from './hora-completa.pipe';
import {HtmlPipe} from './html.pipe';
import {ExtensaoArquivoPipe} from './extensao-arquivo.pipe';
import {FiltroPorExtensaoPipe} from './filtro-por-extensao.pipe';
import {NomeArquivoPipe} from './nome-arquivo.pipe';
import {DataHoraPipe} from './data-hora.pipe';
import {DataExibicaoPipe} from './data-exibicao.pipe';
import {FiltroGenericoPipe} from './filtro-generico.pipe';
import {DataPipe} from './data.pipe';
import {TelefonePipe} from './telefone.pipe';
import {UrlPipe} from './url.pipe';
import {FiltroPrimeiraLetraPipe} from './filtro-primeira-letra.pipe';
import {ArrayMapPipe} from './array-map.pipe';
import {TamanhoArquivoPipe} from './tamanho-arquivo.pipe';
import {CnpjCpfPipe} from './cnpjcpf.pipe';
import {ImagemTokenPipe} from './imagem-token.pipe';
import {SeveridadeLeituraPipe} from './severidade-leitura.pipe';
import {NomeArquivoCurtoPipe} from './nome-arquivo-curso.pipe';

@NgModule({
    declarations: [
        FormatoHoraPipe,
        HoraCompletaPipe,
        HtmlPipe,
        FiltroPorExtensaoPipe,
        ExtensaoArquivoPipe,
        NomeArquivoPipe,
        DataHoraPipe,
        DataExibicaoPipe,
        FiltroGenericoPipe,
        DataPipe,
        TelefonePipe,
        UrlPipe,
        FiltroPrimeiraLetraPipe,
        ArrayMapPipe,
        TamanhoArquivoPipe,
        CnpjCpfPipe,
        ImagemTokenPipe,
        SeveridadeLeituraPipe,
        NomeArquivoCurtoPipe,
    ],
    exports: [
        FormatoHoraPipe,
        HoraCompletaPipe,
        HtmlPipe,
        FiltroPorExtensaoPipe,
        ExtensaoArquivoPipe,
        NomeArquivoPipe,
        DataHoraPipe,
        DataExibicaoPipe,
        FiltroGenericoPipe,
        DataPipe,
        TelefonePipe,
        UrlPipe,
        FiltroPrimeiraLetraPipe,
        ArrayMapPipe,
        TamanhoArquivoPipe,
        CnpjCpfPipe,
        ImagemTokenPipe,
        SeveridadeLeituraPipe,
        NomeArquivoCurtoPipe,
    ],
    imports: [
        CommonModule,
    ]
})
export class PipesModule {
}
