import {Pipe, PipeTransform} from '@angular/core';
import {Funcoes} from '../_helpers/funcoes';

@Pipe({
    name: 'formatohora'
})
export class FormatoHoraPipe implements PipeTransform {
    transform(value: any): any {
        return Funcoes.formatarHora(value);
    }
}
