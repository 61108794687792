import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filtrogenerico'
})
export class FiltroGenericoPipe implements PipeTransform {

    transform(data: any[], atributo: any, termoCondicao: any): any {
        if (!data || !atributo) {
            return data;
        }
        return data.filter(item => item[atributo].toString().indexOf(termoCondicao) !== -1);
    }
}
