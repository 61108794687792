import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, filter, of, Subscription, switchMap, tap} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {catchError, map} from 'rxjs/operators';
import {LocalStorageService} from '../modulos/core/services/localstorage-service';
import {environment} from '../../environments/environment';

@Pipe({
    name: 'imagemtoken',
    pure: false, //indica que o valor pode mudar, e que deve atualizar o valor a cada alteração do ciclo de vida
})
export class ImagemTokenPipe implements PipeTransform, OnDestroy {
    private subscription = new Subscription();
    private transformValue = new BehaviorSubject<string>('');
    private loadingImagePath!: string;
    private errorImagePath!: string;
    private latestValue!: string | SafeUrl;

    constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer, private cdr: ChangeDetectorRef, private localStorageService: LocalStorageService) {
        // every pipe instance will set up their subscription
        this.setUpSubscription();
    }

    transform(imagePath: string): string | SafeUrl {
        this.setLoadingAndErrorImagePaths();
        if (!imagePath) {
            return this.errorImagePath;
        }

        // we emit a new value
        this.transformValue.next(imagePath);

        this.transformValue.error(err => {
            return this.errorImagePath;
        });

        // we always return the latest value
        return this.latestValue || this.loadingImagePath;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private setUpSubscription(): void {
        const headers = new HttpHeaders({'Authorization': 'Bearer ' + this.localStorageService.getData(environment.nomeUsuarioLocalStorage).token, 'Content-Type': 'image/*'});

        const transformSubscription = this.transformValue
            .asObservable()
            .pipe(
                filter((v: any): v is string => !!v),
                distinctUntilChanged(),
                catchError((e: any, err: any) => {
                    return of(this.errorImagePath);
                }),
                // we use switchMap, so the previous subscription gets torn down
                switchMap((imagePath: string) => this.httpClient
                    // we get the imagePath, observing the response and getting it as a 'blob'
                    .get(imagePath, {headers: headers, observe: 'response', responseType: 'blob'})
                    .pipe(
                        // we map our blob into an ObjectURL
                        map((response: HttpResponse<Blob>) => URL.createObjectURL(response.body as any)),
                        // we bypass Angular's security mechanisms
                        map((unsafeBlobUrl: string) => this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)),
                        // we trigger it only when there is a change in the result
                        filter((blobUrl: any) => blobUrl !== this.latestValue),
                        // if the request errors out we return the error image's path value
                        catchError((e: any, err: any) => of(this.errorImagePath))
                    ),
                ),
                tap((imagePath: string | SafeUrl) => {
                    this.latestValue = imagePath;
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
        this.subscription.add(transformSubscription);
    }

    private setLoadingAndErrorImagePaths(): void {
        this.loadingImagePath = 'assets/layout/images/loading.svg';
        this.errorImagePath = 'assets/layout/images/image-error.png';

        /*if (this.loadingImagePath && this.errorImagePath) {
            return;
        }
        if (this.loadingImagePath) {
            this.loadingImagePath = (loadingImagePath as string);
        }
        if (this.errorImagePath) {
            this.errorImagePath = (errorImagePath as string);
        }*/
    }
}
